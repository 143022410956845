import React from 'react'
import './Logo.css'

const Logo = () => {
    return (
      <div>
        <img
          src={process.env.PUBLIC_URL + `/img/pho-gia-logo-background-removed.png`}
          alt="pho gia milwaukie logo"
          className="img"
        />
      </div>
    );
}

export default Logo
