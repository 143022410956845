import React from 'react'
// import ImageSlider from '../UI/ImageSlider'
import About from '../UI/About'
// import './Home.css'

const Home = (props) => {
    return (
      <>
        <About />
        {/* <ImageSlider /> */}
      </>
    );
}

export default Home
